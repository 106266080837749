<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
         <el-form-item label="租户" prop="tenantId" :label-width="formLabelWidth">
            <el-select v-model="formInline.tenantId" clearable placeholder="请选择租户" >
              <el-option v-for="(item,index) in sysTenantList" :key="index" :label="item.tenantName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="名称" prop="regionName">
          <el-input v-model="formInline.regionName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus"  @click="addHandle()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="租户" prop="tenantName" width="180"></el-table-column>
        <el-table-column label="名称" prop="name" width="180"></el-table-column>
        <el-table-column label="负责人" prop="principalName" width="150"></el-table-column>
        <el-table-column label="备注" prop="remark" width="300"></el-table-column>
        <el-table-column label="排序" prop="sort" width="180">
          <template slot-scope="{row}">
            <span>{{row.sort}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="350">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handelDetail(row)">详情</el-button>
            <el-button type="primary" size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="handleselect(row)">设置负责人</el-button>
            <el-button  type="primary" size="mini" @click="handleDelete(row)" style="background:#ff5722">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
          <el-form v-if="dialogStatus === 'detail'" ref='dataFormDetail' :model="dataForm" label-position="center">
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
          <el-form-item  label="负责人" prop="principalName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.principalName" readonly></el-input>
          </el-form-item>
         
          <el-form-item label="备注" prop="remark"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.remark"  readonly></el-input>
          </el-form-item>
          <el-form-item  label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input v-model="dataForm.sort" readonly></el-input>
          </el-form-item>

        </el-form>
        <el-form v-if="dialogStatus === 'edit' || dialogStatus === 'add'"  ref='dataForm' :model="dataForm" :rules="fromrules" label-position="center">
           <el-form-item label="租户" prop="tenantId" :label-width="formLabelWidth">
            <el-select v-model="dataForm.tenantId" clearable placeholder="请选择租户" >
              <el-option v-for="(item,index) in sysTenantList" :key="index" :label="item.tenantName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="dataForm.name" @input="e => dataForm.name= validForbid(e)" show-word-limit maxlength="30"   style="padding:0 48px 0 0"   placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.remark"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   placeholder="请输入备注"></el-input>
          </el-form-item>
        <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input type="number" v-model="dataForm.sort" :min="1" :max="9999"  @input="e => dataForm.sort=parserNumber(e,1,9999)" style="padding:0 48px 0 0"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('dataForm') : editClick('dataForm')">
              立即提交
            </el-button>
            <el-button  v-if="dialogStatus === 'add'"   @click="resetForm('dataForm')">
              重置
            </el-button>
             <el-button  v-if="dialogStatus === 'edit'"  :disabled="true"   @click="resetForm('dataForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'select' "  ref='dataForm' :model="dataForm" :rules="fromrules" label-position="center">
           <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-select v-model="dataForm.principal" clearable placeholder="请选择负责人" >
              <el-option v-for="(item,index) in principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="editClick('dataForm')">
              立即提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { regionData, regionSearch,tenantList, regionDetail, regionPrincipal,regionAdd , regionEdit , regionDel } from '@/api/api.js'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        regionName: '',
        tenantId: ''
      },
      dataForm: {
              id: '',
        tenantId: '',
      tenantName: '',
        name: '',
        principal: '',
        remark: ''
      },
      dialogFormVisible: false,
      formLabelWidth: '100px',
      dialogStatus: '',
      textMap: {
        edit: '编辑区域',
        add: '新增区域',
        select: '设置负责人'
      },
      whether: false,
      sysTenantList: [],
      principalList: [],
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      fromrules: {
        tenantId: [{ required: true, message: '请选择租户', trigger: 'blur' }],
        name: [{ required: true, message: '区域名称不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        tenantId: this.formInline.tenantId,
        regionNameKeywords: this.formInline.regionName
      }
      regionData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
      this.currentPage=1
      var params ={
        page: this.currentPage,
        limit: this.pagesize,
              tenantId: this.formInline.tenantId,
        regionNameKeywords: this.formInline.regionName
      }
      regionSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
     // 租户列表
    getSysTenantList () {
      tenantList().then(res=>{
        this.sysTenantList = res.data.data
      })
    },
      // 负责人列表
    getPrincipalList(tenantId) {
        var params = new URLSearchParams()
          params.append('tenantId', tenantId)
      regionPrincipal(params).then(res=>{
        this.principalList = res.data.data
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      this.$refs.dataForm.resetFields()
    },
    // 重置
    resetForm (dataForm) {
       this.resetTemp()
      if (this.$refs[dataForm].resetFields() !== undefined) {
        this.$refs[dataForm].resetFields()
      }
      this.whether=true
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()

      }
      
      this.dataList()
    },
    resetTemp () {
      this.dataForm = {
            id: '',
       tenantId: '',
        regionName: '',
        principal: '',
        remark: ''
      }
      this.whether=true
    },
    // 新增
    addHandle () {
      this.resetTemp()
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    whetherState () {
      if (this.whether === false) {
        this.dataForm.status = '0'
      } else {
        this.dataForm.status = '1'
      }
    },
    addClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('tenantId', this.dataForm.tenantId)
          params.append('name', this.dataForm.name)
          params.append('remark', this.dataForm.remark)
          params.append('sort', this.dataForm.sort)

          regionAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善区域信息')
        }
      })
    },
    // 详情
    handelDetail (row) {
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 编辑
    handleEdit (row) {
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    handleselect (row) {
      this.getPrincipalList(row.tenantId)
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      
      this.dialogStatus = 'select'
      this.dialogFormVisible = true
    },
    editClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('id', this.dataForm.id)
          params.append('tenantId', this.dataForm.tenantId)
          params.append('name', this.dataForm.name)
          params.append('principal', this.dataForm.principal)
          params.append('remark', this.dataForm.remark)
          params.append('sort', this.dataForm.sort)

          regionEdit(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type:'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else {
              if(res.data.code === 404){
                this.$message.error('系统出现异常，更新失败')
              }else{
                this.$message.error(res.data.msg)
              }
            }
          })
        } else {
          this.$message.error('请完善区域信息')
        }
      })
    },
    submitPrincipal (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('id', this.dataForm.id)
          params.append('tenantId', this.dataForm.tenantId)
          params.append('name', this.dataForm.name)
          //params.append('principal', this.dataForm.principal)
          params.append('remark', this.dataForm.remark)
          params.append('sort', this.dataForm.sort)

          regionEdit(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type:'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else {
              if(res.data.code === 404){
                this.$message.error('系统出现异常，更新失败')
              }else{
                this.$message.error(res.data.msg)
              }
            }
          })
        } else {
          this.$message.error('请完善区域信息')
        }
      })
    },
    // 删除
    handleDelete (row) {
      var _this= this
      this.$confirm('确定刪除 ' + row.regionName + '?', '信息提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        regionDel(row.id).then(res => {
          if(res.data.code === 100){
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            
             if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }
        })
      }).catch((error) => {
        _this.$message.error('删除失败')
      })
    }
  },
  mounted () {
    this.dataList()
    this.getSysTenantList()
  }
}
</script>
